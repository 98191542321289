<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="50%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Actualizar Estado(s)
          <v-spacer></v-spacer>
          <v-btn icon color="default" @click="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="frmEstado">
            <v-select
              auto-select-first
              :items="$store.state.status.filter((v) => v.value != 1)"
              label="ESTADO DE LA COTIZACIÓN"
              v-model="id_status"
              :rules="[(v) => !!v || 'Dato Requerido']"
            ></v-select>
            <v-select
              :items="$store.state.quotes.listRecibidoCotizacion"
              item-value="id"
              item-text="descripcion"
              v-model="id_master_recibidocotizacion"
              label="RECIBIDO COTIZACION EN PRICING"
              :rules="[(v) => !!v || 'Dato Requerido']"
            ></v-select>
            <v-select
              :items="$store.state.quotes.listEnviadoCliente"
              item-value="id"
              item-text="descripcion"
              v-model="id_master_enviadocliente"
              label="ENVIADO AL CLIENTE"
              :rules="[(v) => !!v || 'Dato Requerido']"
            >
            </v-select>
            <v-text-field
              type="date"
              label="FECHA DE ENVÍO AL CLIENTE"
              v-if="
                $store.state.quotes.listEnviadoCliente.filter(
                  (v) => v.id == id_master_enviadocliente && v.codigo == 'SE'
                ).length > 0
              "
              v-model="fecha_enviocliente"
              :rules="[(v) => !!v || 'Dato Requerido']"
            ></v-text-field>
            <v-text-field
              label="COMENTARIO"
              v-model="comentario"
              :rules="[(v) => !!v || 'Dato Requerido']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="updateQuoteRecibidoEnviado"
            >Guardar</v-btn
          >
          <v-btn color="red" dark @click="dialog = !dialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="col-4">
      <v-text-field
        placeholder="Buscar..."
        label="Buscar"
        v-model="search"
      ></v-text-field>
    </div>
    <v-data-table
      v-if="listado.length > 0"
      :search="search"
      :headers="cabCotizaciones"
      :items="listado"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="index"
      show-expand
      class="elevation-1"
    >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr
          :class="[
            item.status_code == 3 ? 't-red' : 't-black',
            isExpanded ? 'filaActivda' : '',
          ]"
        >
          <td></td>
          <td>
            <v-btn-toggle>
              <v-btn
                fab
                x-small
                @click="
                  $router.push({
                    name: 'verCotizacionVista',
                    params: {
                      id: item.id,
                    },
                  })
                "
                v-if="item.status != 'ELIMINADO'"
              >
                <v-icon color="#4A148C" dense small>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="
                  $router.push({
                    name: 'editarCotizacionVista',
                    params: {
                      id: item.id,
                    },
                  })
                "
                v-if="item.status != 'ELIMINADO'"
              >
                <v-icon color="#FB9514" dense small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="abrirModal(item)"
                v-if="item.status != 'ELIMINADO'"
              >
                <v-icon color="#E65100" dense small
                  >mdi-receipt-text-send-outline</v-icon
                >
              </v-btn>
              <v-btn
                fab
                x-small
                @click="registrarLlamada(item.id)"
                v-if="item.status != 'ELIMINADO'"
              >
                <v-icon color="#1A237E" dense small>mdi-phone</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="eliminar(item.id, item.codigo)"
                v-if="item.status != 'ELIMINADO'"
              >
                <v-icon color="#A43542" dense small>mdi-delete</v-icon>
              </v-btn>
            </v-btn-toggle>
          </td>
          <td @click="expand(!isExpanded)">{{ fecha(item.created) }}</td>
          <td @click="expand(!isExpanded)">{{ item.recibidocotizacion }}</td>
          <td @click="expand(!isExpanded)">{{ item.enviadocliente }}</td>
          <td @click="expand(!isExpanded)">{{ item.status }}</td>
          <td @click="expand(!isExpanded)">{{ item.codigo }}</td>
          <td @click="expand(!isExpanded)">{{ item.ejecutivo_ventas }}</td>
          <td @click="expand(!isExpanded)">{{ item.nombres }}</td>
          <td @click="expand(!isExpanded)">{{ item.telefono }}</td>
          <td @click="expand(!isExpanded)">{{ item.sentido }}</td>
          <td @click="expand(!isExpanded)">{{ item.tipo_de_carga }}</td>
          <td @click="expand(!isExpanded)">{{ item.incoterms }}</td>
          <td @click="expand(!isExpanded)">{{ item.origen }}</td>
          <td @click="expand(!isExpanded)">{{ item.destino }}</td>
        </tr>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table>
            <thead class="headerDetails">
              <tr>
                <th v-if="item.details.length > 0">Notas</th>
                <th v-if="item.details.length > 0">Pricing Call</th>
                <th>Fecha Enviado Al Cliente</th>
              </tr>
            </thead>
            <tbody v-if="item.details.length > 0">
              <tr v-for="i in item.details" :key="i">
                <td>{{ i.notes }}</td>
                <td>{{ i.namelong }}</td>
                <td>{{ item.fecha_enviocliente }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>{{ item.fecha_enviocliente }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
// import axios from "axios";

import Swal from "sweetalert2";
import funcion from "@/mixins/funciones";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";
// import moment from "moment";
export default {
  mixins: [funcion],
  props: ["listado", "cabDetalle"],
  data() {
    return {
      id_status: null,
      //
      expanded: [],
      singleExpand: false,
      dialog: false,
      search: "",
      expand: [],
      id_master_recibidocotizacion: null,
      id_master_enviadocliente: null,
      comentario: "",
      fecha_enviocliente: "",
      cabCotizaciones: [
        {
          value: "acciones",
          text: "",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "created",
          text: "FECHA",
          align: "center",
          groupable: true,
          estado: true,
          dataType: "Date",
        },
        {
          value: "recibidocotizacion",
          text: "RECIBIDO COTIZACION EN PRICING",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "enviadocliente",
          text: "ENVIADO AL CLIENTE",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "status",
          text: "ESTATUS",
          align: "center",
          groupable: true,
          estado: true,
        },

        {
          value: "codigo",
          text: "CÓD.",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "ejecutivo_ventas",
          text: "VENDEDOR",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "nombres",
          text: "NOMBRES",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "telefono",
          text: "TÉLEFONO",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "sentido",
          text: "SENTIDO",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "tipo_de_carga",
          text: "CARGA",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "incoterms",
          text: "INCOTERMS",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "origen",
          text: "ORIGEN",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "destino",
          text: "DESTINO",
          align: "center",
          groupable: true,
          estado: true,
        },
        // {
        //   value: "fecha_enviocliente",
        //   text: "FECHA DE ENVIO AL CLIENTE",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
        // {
        //   value: "call_notes",
        //   text: "Último comentario",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
        // { text: "", value: "data-table-expand" },
        // {
        //   value: "call_pricing",
        //   text: "Pricing Call",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
        // {
        //   value: "namemarketing",
        //   text: "MARKETING",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getQuoteList",
      // "getListRecibidoCotizacion",
      // "getListEnviadoCliente",

      "actualizarQuoteRecibidoEnviado",
    ]),
    ...mapMutations([
      "limpiarDatos",
      "setDialogLlamadas",
      "setIdQuote",
      "setDialog",
    ]),
    fechaActual() {
      let data = moment().format("YYYY-MM-DD");

      return data;
    },
    toggleFila(index) {
      this.datos[index].expandida = !this.datos[index].expandida;
    },
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    registrarLlamada(id) {
      console.log(id);
      this.setIdQuote(id);
      this.setDialogLlamadas(true);
    },
    async updateQuoteRecibidoEnviado() {
      if (this.$refs.frmEstado.validate()) {
        this.$store.state.loading = true;
        this.$store.state.progress = 30;
        let data = {
          id: this.quote.id,
          id_master_recibidocotizacion: this.id_master_recibidocotizacion,
          id_master_enviadocliente: this.id_master_enviadocliente,
          fecha_enviocliente:
            this.$store.state.quotes.listEnviadoCliente.filter(
              (v) => v.id == this.id_master_enviadocliente && v.codigo == "SE"
            ).length > 0
              ? this.fecha_enviocliente
              : "",
          id_status: this.id_status,
          comentario: this.comentario,
          id_pricing: JSON.parse(localStorage.getItem("datos")).id_entitie,
        };
        this.$store.state.progress = 60;
        await this.actualizarQuoteRecibidoEnviado(data);
        this.$store.state.progress = 90;
        await this.getQuoteList();
        this.dialog = false;
        this.$store.state.loading = false;
        this.$store.state.progress = 0;
      }
    },
    async eliminarRegistro(id) {
      let data = {
        id: id,
      };
      await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "delQuote",
        data
      );
      this.getQuoteList();
      this.setDialogLlamadas(false);
      this.setDialog(false);
    },
    abrirModal(quote) {
      this.quote = quote;
      this.id_master_recibidocotizacion = quote.id_master_recibidocotizacion;
      this.id_master_enviadocliente = quote.id_master_enviadocliente;
      this.fecha_enviocliente = quote.fecha_enviocliente
        ? quote.fecha_enviocliente
        : this.fechaActual();
      this.id_status = quote.statusquote;
      this.comentario = "";
      this.dialog = true;
    },
    async eliminar(id, codigo) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ADVERTENCIA",
          text: "DESEA ELIMINAR LA COTIZACIÓN CON CÓDIGO: " + codigo,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ACEPTAR",
          cancelButtonText: "CANCELAR",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.eliminarRegistro(id);
            swalWithBootstrapButtons.fire(
              "ELIMINADO!",
              "COTIZACIÓN " + codigo + " ELIMINADA CON ÉXITO",
              "success"
            );
            this.setDialog(false);
          }
        });
      this.setDialog(false);
    },
    fecha(fecha) {
      moment.locale("es");
      let newFecha = moment(fecha).format("YYYY-MMM-DD HH:mm");
      return newFecha;
    },
  },

  mounted() {
    this.fecha_enviocliente = this.fechaActual();
  },
};
</script>

<style scoped>
.headerDetails {
  color: #000 !important;
  background: #80deea !important;
}
.t-red {
  color: red;
}
.t-black {
  color: #000;
}
.filaActivda {
  background: #0097a7;
}
/* #create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
} */
</style>
